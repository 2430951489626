import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

import MenuIcon from "@material-ui/icons/Menu"
import GitHubIcon from "@material-ui/icons/GitHub"
import MailIcon from "@material-ui/icons/Mail"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import TwitterIcon from "@material-ui/icons/Twitter"
import InstagramIcon from "@material-ui/icons/Instagram"
import HomeIcon from "@material-ui/icons/Home"
import CreateIcon from "@material-ui/icons/Create"
import PersonPinIcon from "@material-ui/icons/PersonPin"
import BuildIcon from "@material-ui/icons/Build"

const icons = {
  menu: MenuIcon,
  github: GitHubIcon,
  mail: MailIcon,
  linkedin: LinkedInIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  home: HomeIcon,
  create: CreateIcon,
  personpin: PersonPinIcon,
  build: BuildIcon,
}

const Icon = iconName => {
  const SpecificIcon = icons[iconName]
  return <SpecificIcon />
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}))

const Header = ({ siteTitle }) => {
  const classes = useStyles()

  const [state, setState] = React.useState({
    drawerOpen: false,
  })

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {[
          { text: "Home", icon: "home", path: "/" },
          { text: "About", icon: "personpin", path: "/info/" },
          { text: "Blog", icon: "create", path: "/blog/" },
          // { text: "Projects", icon: "build", path: "/projects/" },
        ].map((item, index) => (
          <Link to={item.path} key={item.text}>
            <ListItem button aria-label={item.text}>
              <ListItemIcon>{Icon(item.icon)}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  )

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, drawerOpen: open })
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="overline" className={classes.title}>
            <Hidden xsDown>
              <Link to="/">{siteTitle}</Link>
            </Hidden>
          </Typography>
          {[
            { icon: "twitter", href: "https://www.twitter.com/_scottlow" },
            { icon: "linkedin", href: "https://www.linkedin.com/in/sclow/" },
            { icon: "github", href: "https://www.github.com/scottlow" },
            { icon: "instagram", href: "https://www.instagram.com/_scottlow/" },
            { icon: "mail", href: "mailto:%73cott@scott%2D%6Cow%2E%63%6Fm" },
          ].map((item, index) => (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label={item.icon}
              key={item.icon}
              href={item.href}
            >
              {Icon(item.icon)}
            </IconButton>
          ))}
        </Toolbar>
      </AppBar>
      <Drawer open={state.drawerOpen} onClose={toggleDrawer(false)}>
        {sideList()}
      </Drawer>
    </div>
  )
}

export default Header
