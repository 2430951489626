/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles"

import blue from "@material-ui/core/colors/blue"
import CssBaseline from "@material-ui/core/CssBaseline"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let theme = createMuiTheme({
    palette: {
      primary: blue,
      secondary: {
        main: "#1976d2",
      },
    },
  })

  theme.typography.h3.fontWeight = 900

  theme = responsiveFontSizes(theme)

  const useStyles = makeStyles({
    "@global": {
      a: {
        textDecoration: "none",
        color: "inherit",
      },
      ".MuiTypography-root": {
        "& a": {
          borderBottom: "2px solid " + theme.palette.primary.main,
          "&:hover": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
  })

  useStyles()

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:900,400,300,700,500,100"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header siteTitle={data.site.siteMetadata.title} />
        <Box py={4}>
          <Container maxWidth="lg">{children}</Container>
        </Box>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
